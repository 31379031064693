import styled from 'styled-components/macro';
import { device } from '../../../../styles/devices';

export const InputsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  width: 100%;

  @media ${device.mobileLrg} {
    grid-template-columns: 1fr 1fr;
  }
`;
