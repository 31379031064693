import React, { useState, useRef } from 'react';
import { Button } from '../../../shared/formElements/button';
import * as Yup from 'yup';
import {
  Form,
  FormContainer,
  FormSectionContainer,
  SectionContainer,
  ButtonGroupContainer,
  LoadingSpinner,
  ImageSplitFormSection,
  Line,
} from '../../../shared/Layout.styles';
import { Markdown } from '../../../shared/markdown';
import { balanceConstants } from '../../../utils/constants/balance';
import { activateCardConstants } from '../../../utils/constants/activate';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../../../shared/formElements/input';
import { BalanceTable } from './sections/BalanceTable';
import {
  Transaction,
  useContextDispatch,
  useContextState,
} from '../../context/context';
import axios from 'axios';
import { IconSectionCard } from '../../../shared/iconSectionCardSection';
import { globalConstants } from '../../../utils/constants/global';
import ReCAPTCHA from 'react-google-recaptcha';
import { sub } from 'date-fns';

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const { activationCode } = activateCardConstants.validations;
var captchaToken: string;
var prevActivationCode: string;
var searchUntil: Date;

export const FreedomBalance = () => {
  const dispatch = useContextDispatch();
  const { transactions, loading, balance, hasMore } = useContextState();

  const [serverErrors, setServerErrors] = useState('');
  const [showBalance, setShowBalance] = useState(false);
  const [isCaptchaCompleted, setCaptchaCompleted] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);

  const { heading, subHeading, balanceText, balanceButtonText, defaultValue } =
    balanceConstants;
  const { expiredCardMessage } = globalConstants;

  const recaptcha = useRef<ReCAPTCHA>(null);

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    shouldFocusError: false,
    defaultValues: {
      activationCode: '',
    },
  });

  // cardNumber: 'VLF8023QB', dummydata
  const onSubmit = async (formData: any) => {
    if (isCaptchaCompleted && !isFormSubmitted) {
      setFormSubmitted(true);
      try {
        setServerErrors('');
        dispatch('START_LOADING');
        const data = {
          params: {
            captchaToken: captchaToken,
            cardNumber: formData.activationCode,
          },
        };

        const resTx = await axios.get<Transaction[]>(
          '/FreedomCardTransactions',
          data
        );
        const resStatus = await axios.get('/FreedomCardStatus', data);

        searchUntil =
          resTx.data.length > 0
            ? new Date(resTx.data[0].occurred_at)
            : sub(new Date(), { days: 180 });

        dispatch('SET_BALANCE_TOTAL', resStatus.data.balance);

        dispatch('NO_MORE');
        dispatch('SET_BALANCES', resTx.data);

        setShowBalance(true);
        dispatch('STOP_LOADING');

        prevActivationCode = formData.activationCode;

        // reset the recaptcha status
        recaptcha.current?.reset();
        onChange('');
      } catch (err: any) {
        if (err.response.status === 404) {
          setServerErrors('Card Not Found');
          console.log('err', err);
        } else if (err.response.status === 500) {
          setServerErrors(err.response.data.message);
        } else if (err.response.status === 700) {
          setServerErrors(expiredCardMessage);
        } else {
          setServerErrors('Card Not Found');
        }
        dispatch('STOP_LOADING');
      }
      setFormSubmitted(false);
    } else {
      if (!isCaptchaCompleted) {
        setServerErrors('Captcha not verified');
      }
    }
  };

  function onChange(value: any) {
    setCaptchaCompleted(Boolean(value));
    captchaToken = value;
    console.log('Captcha value:', value);
  }

  const getMore = () => {};

  const {
    control,
    formState: { errors },
  } = methods;

  return (
    <>
      <Line />
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <SectionContainer>
            <Markdown children={heading} />
            {!showBalance && (
              <Markdown children={subHeading} className="balanceText" />
            )}
            <FormContainer>
              {showBalance ? (
                <BalanceTable
                  transactions={transactions}
                  balance={balance}
                  hasMore={hasMore}
                  getMore={getMore}
                  loading={loading}
                  searchUntil={searchUntil}
                  onChange={onChange}
                  recaptcha={recaptcha}
                  serverErrors={serverErrors}
                />
              ) : (
                <>
                  <FormSectionContainer className="balanceSection">
                    <Controller
                      render={(props) => (
                        <Input
                          {...props}
                          type="text"
                          label="Card Token ID*"
                          fieldName="activationCode"
                          validation={
                            errors?.activationCode?.message || serverErrors
                          }
                          tooltip={
                            <>
                              Your Card Token ID can be found on the back of
                              your card
                              <img
                                src="https://storage.googleapis.com/vault-production-assets/vault-activation-portal/freedom/card-token.png"
                                alt="Back of card"
                              />
                            </>
                          }
                          width="100%"
                        />
                      )}
                      name="activationCode"
                      control={control}
                      defaultValue={
                        prevActivationCode ?? defaultValue.activationCode
                      }
                    />
                    <Markdown children={balanceText} className="subText" />
                    <ReCAPTCHA
                      ref={recaptcha}
                      sitekey="6Lf4yFoqAAAAAJiEpaMnF2oC5k25ESrza_FD3klb"
                      onChange={onChange}
                    />
                    <ButtonGroupContainer>
                      <Button
                        onClick={() => {
                          methods.handleSubmit(onSubmit);
                        }}
                      >
                        {loading ? <LoadingSpinner show /> : balanceButtonText}
                      </Button>
                    </ButtonGroupContainer>
                  </FormSectionContainer>
                  <FormSectionContainer>
                    {process.env.REACT_APP_CARD_OR_ICONS === 'CARD' ? (
                      <ImageSplitFormSection
                        src={process.env.REACT_APP_CARD_FRONT ?? ''}
                      />
                    ) : (
                      <IconSectionCard />
                    )}
                  </FormSectionContainer>
                </>
              )}
            </FormContainer>
          </SectionContainer>
        </Form>
      </FormProvider>
    </>
  );
};

const validationSchema = Yup.object().shape({
  activationCode: Yup.string()
    .required(activationCode.required)
    .matches(/^[a-zA-Z0-9_.-]*$/, {
      message: activationCode.format,
      excludeEmptyString: true,
    }),
});
