import { ImageHeader } from '../Layout.styles';
import {
  HeaderContainer,
  BannerContainer,
  Banner,
  MobileBanner,
} from './Header.styles';
import { useHistory } from 'react-router-dom';

export const Header = () => {
  const { push } = useHistory();

  return (
    <HeaderContainer>
      <BannerContainer>
        {process.env.REACT_APP_BANNER !== 'null' ? (
          <>
            <Banner
              onClick={() => push('/')}
              src={process.env.REACT_APP_BANNER ?? ''}
              alt="Banner Image"
            />
            <MobileBanner
              src={
                'https://ik.imagekit.io/vaultmp/Screen_Shot_2021-12-06_at_4.14.10_pm_5BL3f63YsLv2.png' ??
                ''
              }
              onClick={() => push('/')}
            />
          </>
        ) : (
          <>
            <ImageHeader
              src={process.env.REACT_APP_HEADER_LOGO ?? ''}
              onClick={() => push('/')}
            />
          </>
        )}
      </BannerContainer>
    </HeaderContainer>
  );
};
