import React, {forwardRef} from 'react';
import {useFormContext} from 'react-hook-form';
import {Markdown} from '../../markdown';
import {
    InputElement,
    InputContainer,
    InputAdornmentElement,
    TooltipElement,
    PopperElement,
    HelpIconElement
} from './Input.styles';

export interface IInputProps {
    name?: string;
    label?: string;
    type?: string;
    validation?: string;
    width?: '25%' | '50%' | '75%' | '100%';
    maxLength?: number;
    fieldName: string;
    tooltip?: string | React.ReactNode;
    tooltipLink?: string;
    defaultValue?: string;
    disabled?: boolean;
}

const renderTooltip = (title?: string | React.ReactNode, link?: string) => {
    if (!title) {
        return null;
    }
    const onClick = (link) ? () => {
        window.open(link, '_blank')
    } : () => {
    };
    return (
        <InputAdornmentElement position="end" className='help-icon'>
            <TooltipElement title={title} PopperComponent={PopperElement}>
                <HelpIconElement onClick={onClick}/>
            </TooltipElement>
        </InputAdornmentElement>
    )
}


const Input = forwardRef<HTMLInputElement, IInputProps>(
    ({type, label, validation, width, fieldName, maxLength, tooltip, tooltipLink, defaultValue, disabled}, ref) => {
        const {setValue} = useFormContext();
        return (
            <InputContainer width={width}>
                <InputElement
                    label={label}
                    defaultValue={defaultValue}
                    disabled={disabled ?? false}
                    variant="outlined"
                    inputProps={{maxLength: maxLength}}
                    type={type}
                    error={!!validation}
                    inputRef={ref}
                    onChange={(e) => {
                        setValue(fieldName, e.target.value);
                    }}
                    onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key) && type === 'tel') {
                            e.preventDefault();
                        }
                    }}
                    InputProps={{
                        endAdornment: renderTooltip(tooltip, tooltipLink)
                    }}
                />
                {validation && (
                    <Markdown children={validation} className="validationText"/>
                )}
            </InputContainer>
        );
    }
);
export default Input;
