import { useHistory } from 'react-router';
import { Button } from '../../../shared/formElements/button';
import * as Yup from 'yup';
import {
  Form,
  FormContainer,
  FormSectionContainer,
  ButtonGroupContainer,
  SectionContainer,
  LoadingSpinner,
  Line,
} from '../../../shared/Layout.styles';
import { Markdown } from '../../../shared/markdown';
import { activateCardConstants } from '../../../utils/constants/activate';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContextDispatch, useContextState } from '../../context/context';
import { FreedomActivateCardInputs } from './sections';
import { useState } from 'react';
import axios from 'axios';
import { globalConstants } from '../../../utils/constants/global';
const {
  activationCode,
  firstName,
  lastName,
  termsConditionsCheck,
  email,
  phoneNumber,
} = activateCardConstants.validations;

export const FreedomActivateCard = () => {
  const dispatch = useContextDispatch();
  const { loading } = useContextState();

  const [serverErrors, setServerErrors] = useState('');

  const { heading, subHeading, activateCardText, activateCardButtonText } =
    activateCardConstants;
  const { expiredCardMessage } = globalConstants;

  const { push } = useHistory();

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    shouldFocusError: false,
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      invoiceNo: '',
      cardTokenId: '',
      termsConditionsCheck: false,
    },
  });

  const onSubmit = async (formData: any) => {
    try {
      dispatch('START_LOADING');
      const data = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        invoiceNo: formData.invoiceNo,
        cardTokenId: formData.cardTokenId,
      };
      await axios.post('/ActivateFreedomCard', data);
      dispatch('STOP_LOADING');
      push('/success');
    } catch (err: any) {
      console.log('err', err);
      if (err.response.status === 400 || err.response.status === 404) {
        setServerErrors(err.response.data.message);
      } else if (err.response.status === 700) {
        setServerErrors(expiredCardMessage);
      }
      dispatch('STOP_LOADING');
    }
  };

  return (
    <>
      <Line />
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <SectionContainer>
            <Markdown children={heading} />
            <Markdown children={subHeading} />
            <Markdown children={activateCardText} />
            <FormContainer>
              <FormSectionContainer style={{ width: '100%' }}>
                <FreedomActivateCardInputs />
                {serverErrors && (
                  <Markdown
                    children={serverErrors}
                    className="validationText"
                  />
                )}
                <ButtonGroupContainer>
                  <Button
                    type="submit"
                    onClick={() => {
                      methods.handleSubmit(onSubmit);
                    }}
                  >
                    {loading ? <LoadingSpinner show /> : activateCardButtonText}
                  </Button>
                </ButtonGroupContainer>
              </FormSectionContainer>
            </FormContainer>
          </SectionContainer>
        </Form>
      </FormProvider>
    </>
  );
};
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(firstName.required)
    .matches(/^([A-Za-z\s\-'".])*$/, {
      message: firstName.format,
      excludeEmptyString: true,
    }),
  lastName: Yup.string()
    .required(lastName.required)
    .matches(/^([A-Za-z\s\-'".])*$/, {
      message: lastName.format,
      excludeEmptyString: true,
    }),
  email: Yup.string().email(email.format).required(email.required),
  phone: Yup.string()
    .required(phoneNumber.required)
    .transform((input) => input.replace(/ +/g, ''))
    // Specifically NZ phone number format
    .matches(/^(02|\+642)\d{7,9}$/, {
      message: 'Invalid phone number',
      excludeEmptyString: true,
    }),
  cardTokenId: Yup.string()
    .required('Card Token ID is required')
    .matches(/^[a-zA-Z0-9_.-]*$/, {
      message: activationCode.format,
      excludeEmptyString: true,
    }),
  invoiceNo: Yup.string()
    .required('Invoice Number is required')
    .matches(/^[a-zA-Z0-9_.-]*$/, {
      message: activationCode.format,
      excludeEmptyString: true,
    }),
  termsConditionsCheck: Yup.bool().oneOf([true], termsConditionsCheck.required),
});
