import React from 'react';
import { activateCardConstants } from '../../../../utils/constants/activate';
import { Markdown } from '../../../../shared/markdown';
import { Controller, useFormContext } from 'react-hook-form';
import { CheckboxElement } from '../../../../shared/formElements/checkbox';
import Input from '../../../../shared/formElements/input';
import { InputsGrid } from './FreedomActivateCardInputs.styles';

export const FreedomActivateCardInputs = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { termsConditionsText, defaultValue } = activateCardConstants;

  return (
    <>
      <InputsGrid>
        <Controller
          render={(props) => (
            <Input
              {...props}
              type="text"
              label="First Name*"
              fieldName="firstName"
              validation={errors?.firstName?.message}
              width="100%"
            />
          )}
          name="firstName"
          control={control}
          defaultValue={defaultValue.firstName || ''}
        />
        <Controller
          render={(props) => (
            <Input
              {...props}
              type="text"
              label="Last Name*"
              fieldName="lastName"
              validation={errors?.lastName?.message}
              width="100%"
            />
          )}
          name="lastName"
          control={control}
          defaultValue={defaultValue.lastName || ''}
        />
        <Controller
          render={(props) => (
            <Input
              {...props}
              type="text"
              label="Email Address*"
              fieldName="email"
              validation={errors?.email?.message}
              width="100%"
            />
          )}
          name="email"
          control={control}
          defaultValue=""
        />
        <Controller
          render={(props) => (
            <Input
              {...props}
              type="text"
              label="Mobile Number*"
              fieldName="phone"
              validation={errors?.phone?.message}
              width="100%"
            />
          )}
          name="phone"
          control={control}
          defaultValue=""
        />
        <Controller
          render={(props) => (
            <Input
              {...props}
              type="text"
              label="Invoice Number*"
              fieldName="invoiceNo"
              validation={errors?.invoiceNo?.message}
              // TODO: Put in the image when we have it
              tooltip="Your Invoice Number can be found on your invoice from Freedom"
              width="100%"
            />
          )}
          name="invoiceNo"
          control={control}
          defaultValue=""
        />
        <Controller
          render={(props) => (
            <Input
              {...props}
              type="text"
              label="Card Token ID*"
              fieldName="cardTokenId"
              validation={errors?.cardTokenId?.message}
              tooltip={
                <>
                  Your Card Token ID can be found on the back of your card
                  <img
                    src="https://storage.googleapis.com/vault-production-assets/vault-activation-portal/freedom/card-token.png"
                    alt="Back of card"
                  />
                </>
              }
              width="100%"
            />
          )}
          name="cardTokenId"
          control={control}
          defaultValue={defaultValue.activationCode || ''}
        />
      </InputsGrid>
      <CheckboxElement
        name="termsConditionsCheck"
        label={termsConditionsText}
        className="termsConditionsText"
        control={control}
        validation={errors?.termsConditionsCheck?.message}
      />
    </>
  );
};
